<div id="identities-container">
  <div class="headline-container">
    <h1>{{'headlines.identities' | translate}}</h1>
  </div>

  <mat-divider class="headline-divider"></mat-divider>
  <div class="tree-container dense-form-field-2">
    <div class="col-lg-4">
      <mat-card class="tree">
        <mat-card-content>
          <div class="control-elements">
            <mat-form-field>
              <mat-label>{{'treeview.searchGroups' | translate}}</mat-label>
              <input matInput (keyup)="jumpToNode($event) " placeholder="{{'treeview.search' | translate}}">
              <span matSuffix>{{ foundGroups }}</span>
              <mat-hint>{{'treeview.pressEnterHint' | translate}}</mat-hint>
            </mat-form-field>
          </div>
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" id="tenant-tree">

            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node [ngClass]="{'import-node': node.subType === SubFolderType.LoadMoreUsers, 'highlight': isActiveNode(node)}"
              class="selection-node" *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20"
              [attr.aria-label]="node.name" (click)="nodeSelected(node)">
              <!-- use a disabled button to provide padding for tree leaf -->
              <mat-icon class="subnode-icon">{{node.icon}}</mat-icon>
              <span>{{node.name}}</span>

            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node [ngClass]="{'highlight': isActiveNode(node)}"
              *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20"
              class="selection-node" [attr.aria-label]="node.name" (click)="nodeSelected(node)">
              <button mat-icon-button matTreeNodeToggle class="expand-button" [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
                <mat-icon>{{node.icon}}</mat-icon>
              </button>
              <span>{{node.name}}</span>
              <button mat-icon-button class="sync-button" [attr.aria-label]="'Synchronize ' + node.name"
                *ngIf="node.synced && node.level == 0" [routerLink]="['/lancrypt/connections/import/' + node.identityProviderConnectionId]">
                <mat-icon>sync</mat-icon>
              </button>
            </mat-tree-node>
          </mat-tree>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-8">
      <mat-card>
        <mat-card-content>
          <app-assets-view [activeNode]="activeNode" *ngIf="showAssets"></app-assets-view>
          <app-members-view [activeNode]="activeNode" *ngIf="showMembers"></app-members-view>
          <app-group-view [rootName]="rootName" [activeNode]="activeNode" *ngIf="showGroupView"
            (onSubGroupDeleted)="groupDeleted()"></app-group-view>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
</div>
