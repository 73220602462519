import {Component} from '@angular/core';
import {navItems} from './_nav-items';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './lancrypt-admin-layout.component.html',
  styleUrls: ['./lancrypt-admin-layout.component.scss'],
})
export class LancryptAdminLayoutComponent {
  public navigation = navItems;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(
    private translate: TranslateService,
    public router: Router,
    private breakpointObserver: BreakpointObserver,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'company-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/company-logo.svg')
    );
  }
}
