<table mat-table matSort
       [dataSource]="dataSource" multiTemplateDataRows
       class="mat-elevation-z1">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'treeview.asset' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>
  <ng-container matColumnDef="access">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'treeview.access' | translate}}</th>
    <td mat-cell
        *matCellDef="let element"> {{(element.access !== null ?
      'treeview.assetAccess.' + element.access.toString().toLowerCase() : 'treeview.assetAccess.personal') | translate}} </td>
  </ng-container>
  <ng-container matColumnDef="inherited">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'treeview.inherited' | translate}}</th>
    <td mat-cell
        *matCellDef="let element"> {{element.inherited ? element.rootGroupName : 'treeview.notInherited' | translate}} </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
      </button>
      <button mat-icon-button *ngIf="!element.inherited" aria-label="remove assignment"
              (click)="removeAssignment(element); $event.stopPropagation()" disableDuringRequest
              matTooltip="{{'buttons.unassignAsset' | translate}}">
        <mat-icon>close</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetailAsset">
    <td mat-cell *matCellDef="let element">
      <div class="assets-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="assets-element-description">
          <div>
            {{element.location}}
          </div>
          <div>
            {{'assets.type.' + element.type.toString().toLowerCase() | translate}}
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetailAccess">
    <td mat-cell *matCellDef="let element" [attr.colspan]="2">
      <div class="assets-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="assets-element-description">
          <div>{{element.rootGroupName}}</div>
          <div>{{(element.numberOfGrantAccessGroups != 1 ? 'treeview.assignedToGroups' : 'treeview.assignedToGroup')
            | translate: {amount: element.numberOfGrantAccessGroups} }}</div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
      class="assets-element-row"
      [class.assets-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetailAsset', 'expandedDetailAccess']"
      class="assets-detail-row"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell mat-cell-empty" [attr.colspan]="columnsToDisplayWithExpand.length">
      {{noAssetsRowTextKey | translate}}
    </td>
  </tr>
</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of assets"></mat-paginator>
