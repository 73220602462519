import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {
  AuditLogDetailViewDto,
  AuditLogPageResultDto,
  AuditLogSearchRequestDto,
  AuditLogService,
  AuditLogViewDto,
  ConpalUserViewDto,
  SortOrderDto,
  UserPageResultDto,
  UserSearchRequestDto,
  UserService,
  UserTreeViewDto,
  UserViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from 'src/app/services/toaster.service';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AuditlogDetailComponent} from './components/auditlog-detail/auditlog-detail.component';
import {AuditLogDetailDto} from '../../../dtos/lancrypt/auditLogDetailDto';
import {MatSort} from '@angular/material/sort';
import {TranslationHelperService} from 'src/app/services/helper/translation-helper.service';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {scan} from 'rxjs/operators';
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS} from '../../../shared/lancrypt.constants';

@Component({
  selector: 'app-lancrypt-auditlog',
  templateUrl: './lancrypt-auditlog.component.html',
  styleUrls: ['./lancrypt-auditlog.component.scss'],
})
export class LancryptAuditlogComponent implements OnInit, AfterViewInit {
  pageSize = DEFAULT_PAGE_SIZE;
  currentPage = 0;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  totalRows!: number;

  columnsToDisplay: string[] = ['created', 'type', 'user', 'userId'];
  dataSource: MatTableDataSource<AuditLogViewDto>;

  tenantId!: string;

  auditlogService: AuditLogService;
  userService: UserService;

  filterFormGroup: FormGroup;

  administrators: UserTreeViewDto[] = [];
  users: UserTreeViewDto[] = [];

  translatedTypes: any[] = [];

  sortSettings!: SortOrderDto | undefined;

  userPageCounter = 0;
  userPageCount = 0;

  userObservable: Observable<UserTreeViewDto[]>;
  userOptions!: BehaviorSubject<UserTreeViewDto[]>;

  userIdNameMap: Map<string, string> = new Map<string, string>();
  userFilterCtrl: FormControl = new FormControl();
  userFilter = '';

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private _jwtHelper: JwtHelperService,
    private translateService: TranslateService,
    private _apiClientFactory: ApiClientFactoryService,
    private dialog: MatDialog,
    private toastService: ToastService,
    protected translationHelperService: TranslationHelperService
  ) {
    this.auditlogService = _apiClientFactory.getAuditLogService();
    this.userService = _apiClientFactory.getUserService();

    this.dataSource = new MatTableDataSource<AuditLogViewDto>();

    this.filterFormGroup = new FormGroup({
      dateFrom: new FormControl<Date | null>(this.getPreviousDay(new Date())),
      dateUntil: new FormControl<Date | null>(this.getUntilDate(new Date())),
      type: new FormControl<String | null>(''),
      userId: new FormControl<String | null>(''),
    });

    this.userObservable = this.getUpdatedUserObservable();
  }

  ngAfterViewInit(): void {
    this.sort!.sortChange.subscribe(sortEvent => {
      this.sortSettings = {
        column: sortEvent.active,
        descending: sortEvent.direction === 'desc',
      };
      this.loadAuditLogs();
    });
  }

  ngOnInit(): void {
    this._jwtHelper.getTenantIdFromToken().then(async tenantId => {
      this.tenantId = tenantId;
      await this.loadAdministrators();
      this.loadNextUserBatch();
      this.loadAuditLogs();
    });

    this.translateTypes();

    this.userFilterCtrl.valueChanges.subscribe(value => {
      if (this.userFilter !== value) {
        this.userFilter = value;
        this.userPageCounter = 0;
        this.userObservable = this.getUpdatedUserObservable();
        this.loadNextUserBatch();
      }
    });
  }

  private getUpdatedUserObservable() {
    this.userOptions = new BehaviorSubject<UserTreeViewDto[]>([]);
    return this.userOptions.asObservable().pipe(
      scan((acc, cur) => {
        if (!acc || !cur) return [];
        return [...acc, ...cur];
      })
    );
  }

  openDialog(row: AuditLogViewDto) {
    const detailData: AuditLogDetailDto = {
      auditLog: row,
      user: undefined, //userId is not used
      tenantId: this.tenantId,
    };

    this.dialog.open(AuditlogDetailComponent, {
      maxWidth: '500px',
      hasBackdrop: true,
      data: detailData,
    });
  }

  private translateTypes() {
    const auditLogTypes = AuditLogViewDto.TypeEnum as any;

    for (const type in AuditLogViewDto.TypeEnum) {
      if (auditLogTypes[type] === AuditLogViewDto.TypeEnum.ACCOUNTDELETED) {
        // The ACCOUNT_DELETED event does not make sense for the tenant admin,
        // as the admin will never be able to see this event after the tenant is deleted.
        continue;
      }

      const typeEntry = {
        type: auditLogTypes[type],
        name: this.getTypeName(auditLogTypes[type]),
      };

      this.translatedTypes.push(typeEntry);
    }
  }

  private addUsersToMap(users: UserTreeViewDto[]) {
    for (const user of users) {
      this.userIdNameMap.set(user.id, user.displayName);
    }
  }

  private async loadAdministrators() {
    try {
      const adminUsers = (await firstValueFrom(this.userService.getConpalUsers(this.tenantId))) as ConpalUserViewDto[];
      const adminUserDtos = adminUsers.map(u => {
        return {
          displayName: u.firstName + ' ' + u.lastName,
          emailAddress: u.emailAddress,
          id: u.id,
          rootGroup: '',
          status: UserTreeViewDto.StatusEnum.CONFIRMED,
          syncedUser: false,
        } as UserTreeViewDto;
      });
      this.administrators = adminUserDtos;
      this.addUsersToMap(adminUserDtos);
    } catch (_: any) {
      this.toastService.showError(
        this.translateService.instant('common.error'),
        this.translateService.instant('users.errorLoadingAssignableUsers')
      );
    }
  }

  loadNextUserBatch() {
    const newSearchDto: UserSearchRequestDto = {
      pageRequest: {
        pageNumber: this.userPageCounter,
        pageSize: 10,
      },
      searchString: this.userFilter,
      sortOrder: {
        descending: false,
        column: 'name',
      },
    };

    this.userService.getUsers(newSearchDto, this.tenantId).subscribe({
      next: (n: UserPageResultDto) => {
        this.addUsersToMap(n.content);
        this.userOptions.next(n.content);
        this.userPageCount = n.totalPages;
        this.userPageCounter += 1;
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('users.errorLoadingAssignableUsers')
        );
      },
    });
  }

  getUserNameFromUserId(userId: string): string {
    const userName = this.userIdNameMap.get(userId);
    if (!userName) {
      return '';
    }
    return userName;
  }

  private buildSearchDto(): AuditLogSearchRequestDto {
    const dateFrom = this.filterFormGroup.controls['dateFrom'].value;
    const dateUntil = this.filterFormGroup.controls['dateUntil'].value;
    return {
      pageRequest: {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
      },
      dateFromFilter: dateFrom === null ? undefined : new Date(this.filterFormGroup.controls['dateFrom'].value),
      dateUntilFilter:
        dateUntil === null ? undefined : this.getUntilDate(new Date(this.filterFormGroup.controls['dateUntil'].value)),
      typeFilter: this.filterFormGroup.controls['type'].value,
      userFilter: this.filterFormGroup.controls['userId'].value,
      sortOrder: this.sortSettings,
    };
  }

  private getUntilDate(untilDate: Date): any {
    if (!untilDate) return null;

    return new Date(untilDate.getFullYear(), untilDate.getMonth(), untilDate.getDate(), 23, 59, 59);
  }

  private getPreviousDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
  }

  private loadAuditLogs() {
    const searchDto = this.buildSearchDto();

    this.auditlogService.getAuditLogsForTenant(searchDto, this.tenantId).subscribe({
      next: async (n: AuditLogPageResultDto) => {
        this.dataSource.data = n.content;
        for (const auditLogDto of n.content) {
          this.loadMissingUsername(auditLogDto);
        }
        this.totalRows = n.totalElements;
        this.paginator.pageIndex = this.currentPage;
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('auditlogs.errorGettingAuditLogs')
        );
      },
    });
  }

  private loadMissingUsername(auditLogDto: AuditLogViewDto) {
    if (auditLogDto.userId === undefined) {
      return;
    }

    if (this.userIdNameMap.has(auditLogDto.userId)) {
      return;
    }

    this.userService.getUserByIdWithDeleted(this.tenantId, auditLogDto.userId).subscribe({
      next: (n: UserViewDto) => {
        const displayName = n.firstName + ' ' + n.lastName;
        this.userIdNameMap.set(auditLogDto.userId!, displayName);
      },
      error: async (_: any) => {
        //especially for ACCOUNT_LICENSE_CHANGED or similar events
        this.auditlogService.getAuditLogDetailById(this.tenantId, auditLogDto.id).subscribe({
          next: (n: AuditLogDetailViewDto) => {
            const json = JSON.parse(n.payload);
            const displayName = json['details']['tenantAdminAccountName'];
            this.userIdNameMap.set(auditLogDto.userId!, displayName);
          },
        });
      },
    });
  }

  getTypeName(type: any): any {
    // Get localization key from audit type (e.g. 'ACCOUNT_CREATED' -> 'auditlogs.types.accountCreated')
    const localizationKey = `auditlogs.types.${type.toLowerCase().replace(/([-_]\w)/g, (g: string) => g[1].toUpperCase())}`;
    return this.translateService.instant(localizationKey);
  }

  clearFilter() {
    this.filterFormGroup = new FormGroup({
      dateFrom: new FormControl<Date | null>(null),
      dateUntil: new FormControl<Date | null>(null),
      type: new FormControl<String | null>(''),
      userId: new FormControl<String | null>(''),
    });

    this.currentPage = 0;
    this.sortSettings = undefined;
    this.loadAuditLogs();
  }

  search() {
    this.currentPage = 0;
    this.loadAuditLogs();
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadAuditLogs();
  }
}
