import {NgModule} from '@angular/core';
import {RouterModule, Routes, TitleStrategy} from '@angular/router';

import {AccountLayoutComponent, EmptyLayoutComponent, LancryptAdminLayoutComponent} from './containers';
import {AuthGuard} from './services/auth/authguard';
import {CreateAccountComponent} from './views/public/create-account/create-account.component';

import {LancryptAssetsComponent} from './views/lancrypt/lancrypt-assets/lancrypt-assets.component';
import {LancryptIdentitiesComponent} from './views/lancrypt/lancrypt-identities/lancrypt-identities.component';
import {LancryptSettingsComponent} from './views/lancrypt/lancrypt-settings/lancrypt-settings.component';
import {LancryptConnectionsComponent} from './views/lancrypt/lancrypt-connections/lancrypt-connections.component';

import {PersonalInfoComponent} from './views/account/personal-info/personal-info.component';
import {ContactMgmComponent} from './views/account/contact-mgm/contact-mgm.component';
import {GroupCreateComponent} from './views/lancrypt/lancrypt-identities/components/group-create/group-create.component';
import {GroupUpdateComponent} from './views/lancrypt/lancrypt-identities/components/group-update/group-update.component';
import {UserCreateComponent} from './views/lancrypt/lancrypt-identities/components/user-create/user-create.component';
import {UserUpdateComponent} from './views/lancrypt/lancrypt-identities/components/user-update/user-update.component';
import {UserDetailsComponent} from './views/lancrypt/lancrypt-identities/components/user-details/user-details.component';
import {UserActivateAccountComponent} from './views/public/user-activate-account/user-activate-account.component';
import {AssetCreateComponent} from './views/lancrypt/lancrypt-assets/components/asset-create/asset-create.component';
import {AssetUpdateComponent} from './views/lancrypt/lancrypt-assets/components/asset-update/asset-update.component';
import {AssetsAssignComponent} from './views/lancrypt/lancrypt-identities/components/assets-assign/assets-assign.component';
import {MembersAssignComponent} from './views/lancrypt/lancrypt-identities/components/members-assign/members-assign.component';
import {AccountHomeComponent} from './views/account/account-home/account-home.component';
import {LancryptDashboardComponent} from './views/lancrypt/lancrypt-dashboard/lancrypt-dashboard.component';
import {LancryptTitleStrategy} from './services/lancrypt-title-strategy';
import {VerifyEmailComponent} from './views/public/verify-email/verify-email.component';
import {LancryptAuditlogComponent} from './views/lancrypt/lancrypt-auditlog/lancrypt-auditlog.component';
import {ConnectionCreateComponent} from './views/lancrypt/lancrypt-connections/components/connection-create/connection-create.component';
import {ConnectionUpdateComponent} from './views/lancrypt/lancrypt-connections/components/connection-update/connection-update.component';
import {ConnectionImportComponent} from './views/lancrypt/lancrypt-connections/components/connection-import/connection-import.component';
import {ConnectionImportJobsComponent} from './views/lancrypt/lancrypt-connections/components/connection-import-jobs/connection-import-jobs.component';
import {SubscriptionsComponent} from './views/account/subscriptions/subscriptions.component';
import {AdministratorsComponent} from './views/account/administrators/administrators.component';
import {AdministratorCreateComponent} from './views/account/administrators/components/administrator-create/administrator-create.component';
import {AdministratorUpdateComponent} from './views/account/administrators/components/administrator-update/administrator-update.component';
import {ClientLoginSuccessComponent} from './views/public/client-login-success/client-login-success.component';
import {DataExportComponent} from './views/account/data-export/data-export.component';
import {ClientsComponent} from './views/account/clients/clients.component';
import {userDataResolver} from './services/user-data.service';
import {tenantDataResolver} from './services/tenant-data.service';
import {featureFlagDataResolver, featureFlagGuard} from './services/feature-flag.service';
import {AppRootComponent} from './app-root.component';
import {TenantRootComponent} from './tenant-root.component';
import {translationDataResolver} from './services/helper/translation-helper.service';

const routes: Routes = [
  {
    path: '',
    component: AppRootComponent,
    children: [
      {
        path: '',
        redirectTo: 'lancrypt/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'public',
        component: EmptyLayoutComponent,
        resolve: {translation: translationDataResolver},
        children: [
          {
            path: '',
            redirectTo: 'create-account',
            pathMatch: 'full',
          },
          {
            path: 'create-account',
            pathMatch: 'full',
            title: 'titles.createAccount',
            component: CreateAccountComponent,
          },
          {
            path: 'activate-account/:userId',
            pathMatch: 'full',
            title: 'titles.activateAccount',
            component: UserActivateAccountComponent,
          },
          {
            path: 'verify-email/:email',
            pathMatch: 'full',
            component: VerifyEmailComponent,
          },
          {
            path: 'login-success',
            pathMatch: 'full',
            component: ClientLoginSuccessComponent,
          },
        ],
      },
      {
        path: '',
        canActivate: [AuthGuard, featureFlagGuard],
        resolve: {userData: userDataResolver, tenantData: tenantDataResolver},
        component: TenantRootComponent,
        children: [
          {
            path: '',
            redirectTo: 'lancrypt/dashboard',
            pathMatch: 'full',
          },
          {
            path: 'lancrypt',
            resolve: {translation: translationDataResolver, featureFlag: featureFlagDataResolver},
            component: LancryptAdminLayoutComponent,
            children: [
              {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
              },
              {
                path: 'dashboard',
                pathMatch: 'full',
                title: 'titles.dashboard',
                component: LancryptDashboardComponent,
              },
              {
                path: 'auditlog',
                pathMatch: 'full',
                title: 'titles.auditlog',
                component: LancryptAuditlogComponent,
              },
              {
                path: 'assets',
                pathMatch: 'full',
                title: 'titles.assets',
                component: LancryptAssetsComponent,
              },
              {
                path: 'assets/create-asset',
                pathMatch: 'full',
                title: 'titles.createAsset',
                component: AssetCreateComponent,
              },
              {
                path: 'assets/edit-asset/:assetId',
                pathMatch: 'full',
                title: 'titles.editAsset',
                component: AssetUpdateComponent,
              },
              {
                path: 'identities',
                pathMatch: 'full',
                title: 'titles.identities',
                component: LancryptIdentitiesComponent,
              },
              {
                path: 'identities/users',
                pathMatch: 'full',
                title: 'titles.identities',
                component: LancryptIdentitiesComponent,
              },
              {
                path: 'identities/group/:groupId/:subfolder',
                pathMatch: 'full',
                title: 'titles.identities',
                component: LancryptIdentitiesComponent,
              },
              {
                path: 'identities/assets/assign/group/:groupId',
                pathMatch: 'full',
                title: 'titles.assignAsset',
                component: AssetsAssignComponent,
              },
              {
                path: 'identities/members/assign/group/:groupId',
                pathMatch: 'full',
                title: 'titles.assignMember',
                component: MembersAssignComponent,
              },
              {
                path: 'identities/create-group/:parentGroupId/:parentGroupName',
                pathMatch: 'full',
                title: 'titles.createGroup',
                component: GroupCreateComponent,
              },
              {
                path: 'identities/edit-group/:groupId',
                pathMatch: 'full',
                title: 'titles.editGroup',
                component: GroupUpdateComponent,
              },
              {
                path: 'identities/create-user',
                pathMatch: 'full',
                title: 'titles.createUser',
                component: UserCreateComponent,
              },
              {
                path: 'identities/edit-user/:userId',
                pathMatch: 'full',
                title: 'titles.editUser',
                component: UserUpdateComponent,
              },
              {
                path: 'identities/edit-user/:userId/:groupId',
                pathMatch: 'full',
                title: 'titles.editUser',
                component: UserUpdateComponent,
              },
              {
                path: 'identities/details-user/:userId',
                pathMatch: 'full',
                title: 'titles.detailsUser',
                component: UserDetailsComponent,
              },
              {
                path: 'identities/details-user/:userId/:groupId',
                pathMatch: 'full',
                title: 'titles.detailsUser',
                component: UserDetailsComponent,
              },
              {
                path: 'settings',
                pathMatch: 'full',
                title: 'titles.settings',
                component: LancryptSettingsComponent,
              },
              {
                path: 'connections',
                pathMatch: 'full',
                title: 'titles.connections',
                component: LancryptConnectionsComponent,
              },
              {
                path: 'connections/create-connection',
                pathMatch: 'full',
                title: 'titles.createConnection',
                component: ConnectionCreateComponent,
              },
              {
                path: 'connections/edit-connection/:connectionId',
                pathMatch: 'full',
                title: 'titles.editConnection',
                component: ConnectionUpdateComponent,
              },
              {
                path: 'connections/import/:connectionId',
                pathMatch: 'full',
                title: 'titles.connectionImport',
                component: ConnectionImportComponent,
              },
              {
                path: 'connections/import-jobs',
                pathMatch: 'full',
                title: 'titles.importJobs',
                component: ConnectionImportJobsComponent,
              },
            ],
          },
          {
            path: 'account',
            resolve: {translation: translationDataResolver, featureFlag: featureFlagDataResolver},
            component: AccountLayoutComponent,
            children: [
              {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
              },
              {
                path: 'home',
                pathMatch: 'full',
                title: 'titles.home',
                component: AccountHomeComponent,
              },
              {
                path: 'info',
                pathMatch: 'full',
                title: 'titles.accountInfo',
                component: PersonalInfoComponent,
              },
              {
                path: 'contact',
                pathMatch: 'full',
                title: 'titles.contact',
                component: ContactMgmComponent,
              },
              {
                path: 'subscriptions',
                pathMatch: 'full',
                title: 'titles.subscriptions',
                component: SubscriptionsComponent,
              },
              {
                path: 'administrators',
                pathMatch: 'full',
                title: 'titles.administrators',
                component: AdministratorsComponent,
              },
              {
                path: 'administrators/create-administrator',
                pathMatch: 'full',
                title: 'titles.createAdministrator',
                component: AdministratorCreateComponent,
              },
              {
                path: 'administrators/edit-administrator/:administratorId',
                pathMatch: 'full',
                title: 'titles.editAdministrator',
                component: AdministratorUpdateComponent,
              },
              {
                path: 'clients',
                pathMatch: 'full',
                title: 'titles.clients',
                component: ClientsComponent,
              },
              {
                path: 'data-export',
                pathMatch: 'full',
                title: 'titles.dataExport',
                component: DataExportComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'lancrypt/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: LancryptTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
