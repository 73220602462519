export const FIELD_LENGTH_CONSTRAINTS = {
  firstName: 100,
  lastName: 100,
  street1: 100,
  street2: 100,
  city: 100,
  state: 100,
  postalCode: 20,
  country: 100,
  email: 254,
  passwordMin: 8,
  passwordMax: 64,
  companyName: 100,
  mobilePhoneNumber: 50,
  businessPhoneNumber: 50,
  description: 254,
  asset: {
    name: 254,
    type: 255,
  },
  identityProviderConnection: {
    name: 200,
    clientId: 100,
    azureTenantId: 100,
    clientSecret: 100,
  },
  keyServerSettings: {
    hostname: 100,
    username: 254,
    password: 64,
  },
};

// Password requirements:
//  * lower case chars
//  * upper case chars
//  * digits
//  * special chars: !@#$%^&*()_+-=[]{};':"|,.<>\/?`~§±
export const PASSWORD_REQUIREMENTS_PATTERN =
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\\-\\=\\[\\]{};\':"\\|,.<>\\\\/?`~§±]).*';

export const DEFAULT_DEBOUNCE_TIME = 300;
export const DEFAULT_DROPDOWN_PAGE_SIZE = 50;

export const DEFAULT_PAGE_SIZE_OPTIONS: number[] = [10, 20, 50, 100];
export const DEFAULT_PAGE_SIZE = 10;
export const PEM_HEADER_BEGIN = '-----BEGIN CERTIFICATE-----';
export const PEM_HEADER_END = '-----END CERTIFICATE-----';
