import {Injectable, inject} from '@angular/core';
import {ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {PersonalInfoDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from './apiclient-factory.service';
import {JwtHelperService} from './helper/jwt-helper.service';
import {UserData} from '../dtos/lancrypt/user-data';
import {LogoutService} from './auth/logout.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  userData: BehaviorSubject<UserData>;

  constructor() {
    this.userData = new BehaviorSubject<UserData>({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      tenantId: '',
      languageInfo: '',
      regionalFormats: '',
    });
  }
}

export const userDataResolver: ResolveFn<UserData> = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const jwtHelperService = inject(JwtHelperService);
  const userService = inject(ApiClientFactoryService).getUserService();
  const userDataService = inject(UserDataService);
  const logoutService = inject(LogoutService);

  try {
    const tenantId = await jwtHelperService.getTenantIdFromToken();
    const personalInfo = (await firstValueFrom(userService.getPersonalInfo(tenantId))) as PersonalInfoDto;
    const userData: UserData = {
      id: personalInfo.id,
      firstName: personalInfo.firstName,
      lastName: personalInfo.lastName,
      email: personalInfo.email,
      tenantId: tenantId,
      languageInfo: personalInfo.languageInfo,
      regionalFormats: personalInfo.regionalFormats,
    };
    userDataService.userData.next(userData);
    return userData;
  } catch {
    logoutService.logout();
    return {} as UserData;
  }
};

export {UserData} from '../dtos/lancrypt/user-data';
