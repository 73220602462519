import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AssetPageResultDto,
  AssetSearchRequestDto,
  AssetService,
  AssetViewDto,
  AssignAssetCreateDto,
  SortOrderDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {TranslateService} from '@ngx-translate/core';
import {SubFolderType} from 'src/app/dtos/lancrypt/GroupTreeViewIconDto';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {TranslationHelperService} from 'src/app/services/helper/translation-helper.service';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {ToastService} from 'src/app/services/toaster.service';
import {MatSort} from '@angular/material/sort';
import {debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
} from '../../../../../shared/lancrypt.constants';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-assets-assign',
  templateUrl: './assets-assign.component.html',
  styleUrls: ['./assets-assign.component.scss'],
})
export class AssetsAssignComponent implements OnInit, AfterViewInit {
  private assetService: AssetService;

  pageSize = DEFAULT_PAGE_SIZE;
  currentPage = 0;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  totalRows!: number;

  sortSettings: SortOrderDto = {
    descending: false,
    column: 'name',
  };

  nameFilterValue = '';
  nameFilter = new Subject<string>();
  nameFilterTrigger = this.nameFilter.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged());

  inheritAssetAssignment = true;

  displayedColumns: string[] = ['select', 'name', 'location', 'type', 'granted'];
  dataSource = new MatTableDataSource<AssetViewDto>();
  selection = new SelectionModel<AssetViewDto>(true, []);

  groupId = '';
  tenantId = '';

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  private assignedAssetIds: Set<string> = new Set<string>();

  constructor(
    private translationHelperService: TranslationHelperService,
    protected toastService: ToastService,
    protected jwtHelperService: JwtHelperService,
    private apiClientFactory: ApiClientFactoryService,
    protected translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.assetService = this.apiClientFactory.getAssetService();
    this.dataSource = new MatTableDataSource<AssetViewDto>();
    this.setupSortingProperties();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;

      this.route.params.subscribe(params => {
        this.groupId = params['groupId'];

        this.assetService.getGroupAssignedAssets(this.tenantId, this.groupId, false).subscribe({
          next: (n: AssetViewDto[]) => {
            n.forEach(assetViewDto => {
              this.assignedAssetIds.add(assetViewDto.id);
            });
            this.loadAssets();
          },
        });
      });
    });

    this.nameFilterTrigger.subscribe(currentValue => {
      this.currentPage = 0;
      this.nameFilterValue = currentValue.trim().toLowerCase();
      this.loadAssets();
    });
  }

  ngAfterViewInit(): void {
    this.sort!.sortChange.subscribe(sortEvent => {
      this.sortSettings = {
        column: sortEvent.active,
        descending: sortEvent.direction === 'desc',
      };
      this.loadAssets();
    });
  }

  applyFilter(event: Event) {
    this.nameFilter.next((event.target as HTMLInputElement).value);
  }

  getAssignCreateDto(): AssignAssetCreateDto {
    return {
      inheritAccess: this.inheritAssetAssignment,
      groupId: this.groupId,
      assetIds: Array.from(this.assignedAssetIds.values()),
    };
  }

  cancel() {
    this.router.navigate(['/lancrypt/identities/group/' + this.groupId + '/' + SubFolderType.Assets]);
  }

  addAssignment() {
    const createDto = this.getAssignCreateDto();

    this.assetService.assignAssetsToGroup(createDto, this.tenantId, this.groupId).subscribe({
      next: async (_: any) => {
        this.toastService.showSuccess(
          this.translateService.instant('common.success'),
          this.translateService.instant('assets.successfullAssigned')
        );
        this.router.navigate(['/lancrypt/identities/group/' + this.groupId + '/' + SubFolderType.Assets]);
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('assets.errors.assigningAssets')
        );
      },
      complete: () => {},
    });
  }

  getAccessGrantedString(asset: AssetViewDto) {
    return this.translationHelperService.renderUsersAndGroupsText(
      asset.grantAccessUsers.length,
      asset.grantAccessGroups.length
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (!this.isAllSelected()) {
      this.selection.select(...this.dataSource.data);
      this.dataSource.data.forEach(assetViewDto => {
        this.assignedAssetIds.add(assetViewDto.id);
      });
    } else {
      this.selection.selected.forEach(assetViewDto => {
        this.assignedAssetIds.delete(assetViewDto.id);
      });
      this.selection.clear();
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: AssetViewDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name}`;
  }

  private loadAssets() {
    const searchRequestDto: AssetSearchRequestDto = {
      pageRequest: {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
      },
      sortOrder: this.sortSettings,
      searchString: this.nameFilterValue,
    };

    this.assetService.getTenantAssetsOrderByGroup(searchRequestDto, this.tenantId!, this.groupId).subscribe({
      next: async (n: AssetPageResultDto) => {
        this.dataSource.data = n.content;
        this.totalRows = n.totalElements;
        this.paginator.pageIndex = this.currentPage;

        this.setSelection();
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('assets.errors.loadingAssignableAssets')
        );
      },
    });
  }

  private setSelection() {
    const selected = this.dataSource.data.filter(value => {
      return this.assignedAssetIds.has(value.id);
    });
    this.selection.setSelection(...selected);
  }

  private setupSortingProperties() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'name':
          return data.name.toLowerCase();
        case 'location':
          return data.location.toLowerCase();
        case 'granted':
          return this.getAccessGrantedString(data);
        default:
          return (data as any)[sortHeaderId];
      }
    };
  }

  toggleSelection(row: AssetViewDto) {
    if (!this.selection.isSelected(row)) {
      this.assignedAssetIds.add(row.id);
    } else {
      this.assignedAssetIds.delete(row.id);
    }
    this.selection.toggle(row);
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadAssets();
  }
}
