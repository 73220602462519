import {Component} from '@angular/core';

@Component({
  selector: 'app-dashboard-account',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss'],
})
export class AccountLayoutComponent {
  constructor() {}

  ngOnInit(): void {}
}
