import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../environments/environment';
import {FeatureFlagService} from '../feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private keycloakService: KeycloakService,
    private router: Router,
    private featureFlagService: FeatureFlagService
  ) {}

  public logout() {
    this.keycloakService.logout(environment.keycloak.redirectUrl).then(_ => {
      this.router.navigate(['/']);
    });

    this.featureFlagService.clear();
  }
}
