<table mat-table matSort
       [dataSource]="dataSource" multiTemplateDataRows
       class="mat-elevation-z1">

  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'treeview.fields.' + column | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{ element[column] }}</td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="delete group"
              *ngIf="showDelete(element)" (click)="deleteGroup(element); $event.stopPropagation()" disableDuringRequest
              matTooltip="{{ 'buttons.deleteGroup' | translate }}">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithDelete"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithDelete;" class="element-row">
  </tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell mat-cell-empty" [attr.colspan]="columnsToDisplayWithDelete.length">
      <div *onlyShowWhenIdle>{{ noGroupsRowTextKey | translate }}</div>
    </td>
  </tr>
</table>
<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
               showFirstLastButtons></mat-paginator>
<mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
