<mat-expansion-panel class="mat-elevation-z1" [(expanded)]="expanded">
    <mat-expansion-panel-header class="title-card-header">
        <mat-panel-title class="settings-header">
            <h2>{{'settings.keyServer.title' | translate}}</h2>
            <mat-icon class="settings-info-icon" matTooltip="{{'settings.keyServer.hint' | translate}}">info</mat-icon>

        </mat-panel-title>
        <mat-action-row (click)=$event.stopPropagation()>
            <button mat-button class="edit-buton" (click)="onEdit()" color="lancrypt-primary-light"
                [disabled]="!settingsViewDto" [hidden]="editMode">
                {{'buttons.edit' | translate}}
            </button>
            <button mat-button class="save-button" (click)="onSave()" color="lancrypt-primary-light"
                [hidden]="!editMode">
                {{'buttons.save' | translate}}
            </button>
            <button mat-button class="cancel-button" (click)="onCancel()" [hidden]="!editMode">
                {{'buttons.cancel' | translate}}
            </button>
        </mat-action-row>
    </mat-expansion-panel-header>
    <mat-card style="box-shadow: none">
        <mat-card-content>
            <form [formGroup]="formGroup">
                <div class="settings-item">
                    <span class="settings-title">
                        <mat-label class="settings-name">{{'settings.keyServer.username' | translate}}</mat-label>
                    </span>
                    <span class="settings-value">
                        <mat-form-field class="settings-field">
                            <input matInput [formControl]="formGroup.controls.username">
                            <mat-error *ngIf="formGroup.hasError('required','username')">
                                {{'settings.errors.usernameRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formGroup.hasError('maxlength','username')">
                                {{'settings.errors.usernameMaxLength' | translate: {maxLength:
                                maxLengthConstraints.username} }}
                            </mat-error>
                        </mat-form-field>
                    </span>
                </div>
                <div class="settings-item">
                    <span class="settings-title">
                        <mat-label class="settings-name">{{'settings.keyServer.password' | translate}}</mat-label>
                    </span>
                    <span class="settings-value">
                        <mat-form-field class="settings-field">
                            <input matInput type="password" [formControl]="formGroup.controls.password">
                            <mat-error *ngIf="formGroup.hasError('required','password')">
                                {{'settings.errors.passwordRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formGroup.hasError('maxlength','password')">
                                {{'settings.errors.passwordMaxLength' | translate: {maxLength:
                                maxLengthConstraints.password} }}
                            </mat-error>
                        </mat-form-field>
                    </span>
                </div>
                <div class="settings-item">
                    <span class="settings-title">
                        <mat-label class="settings-name">{{'settings.keyServer.hostname' | translate}}</mat-label>
                    </span>
                    <span class="settings-value">
                        <mat-form-field class="settings-field">
                            <input matInput [formControl]="formGroup.controls.hostname">
                            <mat-error *ngIf="formGroup.hasError('required','hostname')">
                                {{'settings.errors.hostnameRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="formGroup.hasError('maxlength','hostname')">
                                {{'settings.errors.hostnameMaxLength' | translate: {maxLength:
                                maxLengthConstraints.hostname} }}
                            </mat-error>
                        </mat-form-field>
                    </span>
                </div>
                <div class="settings-item">
                    <span class="settings-title">
                        <mat-label class="settings-name">{{'settings.keyServer.certificates' | translate}}</mat-label>
                    </span>
                    <span class="settings-value">
                        <mat-card class="certificate-view" *ngFor="let cert of certs; index as i">
                            <mat-card-content>
                                <div class="cert-info">
                                    {{'settings.keyServer.certificateIssuer' | translate}}: {{cert.issuer}} <br>
                                    {{'settings.keyServer.certificateValidFrom' | translate}}: {{cert.notBefore | date:
                                    'short'}} <br>
                                    {{'settings.keyServer.certificateValidUntil' | translate}}: {{cert.notAfter | date:
                                    'short'}} <br>
                                    {{'settings.keyServer.certificateSerial' | translate}}: {{cert.serialNumber}}
                                </div>
                                <button [hidden]="!editMode" mat-icon-button class="trashcan"
                                    (click)="onDelete(i); $event.stopPropagation()" disableDuringRequest
                                    matTooltip="{{ 'settings.keyServer.buttonRemove' | translate }}">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-card-content>

                        </mat-card>

                        <mat-card [hidden]="!editMode">
                            <mat-card-header>
                                {{'settings.keyServer.addCertificate' | translate}}
                                <mat-icon class="settings-info-icon" inline="true"
                                    matTooltip="{{'settings.keyServer.addCertificateHint' | translate: {begin: pemMakers.begin, end: pemMakers.end} }}">info</mat-icon>
                            </mat-card-header>

                            <mat-card-content>
                                <mat-form-field class="certificate-input">
                                    <textarea matInput [formControl]="formGroup.controls.certificate"></textarea>
                                    <mat-error *ngIf="formGroup.hasError('invalidCert','certificate')">
                                        {{'settings.errors.invalidCertFormat' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </mat-card-content>

                            <mat-card-footer>
                                <button mat-raised-button class="add-button" (click)="onAdd()">Add</button>
                            </mat-card-footer>
                        </mat-card>
                    </span>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</mat-expansion-panel>