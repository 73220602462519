import {Injectable} from '@angular/core';
import {GroupTreeViewIconDto, SubFolderType} from '../dtos/lancrypt/GroupTreeViewIconDto';
import {TranslateService} from '@ngx-translate/core';
import {GroupTreeViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

export enum GroupTreeTrait {
  None = 0,
  ShowUsers = 1 << 0,
  ShowAllUsers = 1 << 1,
  ShowMembers = 1 << 2,
  ShowAssets = 1 << 3,
  All = ShowUsers | ShowAllUsers | ShowMembers | ShowAssets,
}

@Injectable({
  providedIn: 'root',
})
export class GroupTreeService {
  constructor(private translateService: TranslateService) {}

  async buildTreeView(
    groupTrees: GroupTreeViewDto[],
    groupTreeTraits: GroupTreeTrait
  ): Promise<GroupTreeViewIconDto[]> {
    const roots: GroupTreeViewIconDto[] = [];
    for (const groupTree of groupTrees) {
      const groupIconTree = <GroupTreeViewIconDto>groupTree;

      await this.sort(groupIconTree);
      await this.addTraits(groupIconTree, groupTreeTraits);
      roots.push(groupIconTree);
    }

    // Add 'All Users' node
    if ((groupTreeTraits & GroupTreeTrait.ShowAllUsers) === GroupTreeTrait.ShowAllUsers) {
      const allUsersFolder: GroupTreeViewIconDto = {
        id: '',
        name: this.translateService.instant('treeview.allUsers'),
        children: [],
        icon: 'supervised_user_circle',
        subType: SubFolderType.AllUsers,
        groupName: '',
        parents: [],
        description: '',
        treeHierarchyDirection: 'ANCESTORS',
        root: true,
        syncedGroup: false,
        identityProviderConnectionId: '',
      };
      roots.push(allUsersFolder);
    }

    return roots;
  }

  private async sort(node: GroupTreeViewIconDto) {
    node.children.sort((a, b) => (a.name < b.name ? -1 : 1));
    for (let i = 0; i < node.children.length; i++) {
      await this.sort(node.children[i]);
    }
  }

  private async addTraits(node: GroupTreeViewIconDto, groupTreeTraits: GroupTreeTrait) {
    if (node.syncedGroup) {
      node.icon = node.root ? 'domain_add' : 'group_add';
    } else {
      node.icon = node.root ? 'domain' : 'group';
    }

    node.subType = SubFolderType.Group;

    // Add traits recursively to the whole tree
    for (let i = 0; i < node.children.length; i++) {
      await this.addTraits(node.children[i], groupTreeTraits);
    }

    const children: GroupTreeViewIconDto[] = [];

    // Add 'Assets' node
    if ((groupTreeTraits & GroupTreeTrait.ShowAssets) === GroupTreeTrait.ShowAssets) {
      const assetFolder: GroupTreeViewIconDto = {
        id: node.id,
        name: this.translateService.instant('treeview.assets'),
        children: [],
        icon: 'widgets',
        subType: SubFolderType.Assets,
        groupName: node.name,
        parents: [],
        description: '',
        treeHierarchyDirection: 'ANCESTORS',
        root: false,
        syncedGroup: node.syncedGroup,
        identityProviderConnectionId: node.identityProviderConnectionId,
      };
      children.push(assetFolder);
    }

    // Add 'Members' node (only to subgroups)
    if (!node.root && (groupTreeTraits & GroupTreeTrait.ShowMembers) === GroupTreeTrait.ShowMembers) {
      const memberFolder: GroupTreeViewIconDto = {
        id: node.id,
        name: this.translateService.instant('treeview.members'),
        children: [],
        icon: 'person_add',
        subType: SubFolderType.Members,
        groupName: node.name,
        parents: [],
        description: '',
        treeHierarchyDirection: 'ANCESTORS',
        root: false,
        syncedGroup: node.syncedGroup,
        identityProviderConnectionId: node.identityProviderConnectionId,
      };
      children.push(memberFolder);
    }

    // Add 'Users' node (only to root nodes)
    if (node.root && (groupTreeTraits & GroupTreeTrait.ShowUsers) === GroupTreeTrait.ShowUsers) {
      const userFolder: GroupTreeViewIconDto = {
        id: node.id,
        name: this.translateService.instant('treeview.users'),
        children: [],
        icon: 'account_circle',
        subType: SubFolderType.Users,
        groupName: node.name,
        parents: [],
        description: '',
        treeHierarchyDirection: 'ANCESTORS',
        root: false,
        syncedGroup: node.syncedGroup,
        identityProviderConnectionId: node.identityProviderConnectionId,
      };
      children.push(userFolder);
    }

    node.children.push(...children);
  }
}
