import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';

// Import routing module
import {AppRoutingModule} from './app-routing.module';

// Import app component
import {AppComponent} from './app.component';

// Import containers
import {
  AccountFooterComponent,
  AccountHeaderComponent,
  AccountLayoutComponent,
  EmptyFooterComponent,
  EmptyHeaderComponent,
  EmptyLayoutComponent,
  LancryptAdminFooterComponent,
  LancryptAdminHeaderComponent,
  LancryptAdminLayoutComponent,
} from './containers';

import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FooterModule,
  FormModule,
  GridModule,
  HeaderModule,
  ImgModule,
  ListGroupModule,
  NavModule,
  ProgressModule,
  SharedModule,
  SidebarModule,
  TabsModule,
  UtilitiesModule,
} from '@coreui/angular';

import {IconModule, IconSetService} from '@coreui/icons-angular';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';

import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';

import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {UserMenuModule} from './shared/components/user-menu/user-menu.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LancryptIdentitiesModule} from './views/lancrypt/lancrypt-identities/lancrypt-identities.module';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {PersonalInfoModule} from './views/account/personal-info/personal-info.module';
import {AccountHomeModule} from './views/account/account-home/account-home.module';
import {LancryptAssetsModule} from './views/lancrypt/lancrypt-assets/lancrypt-assets.module';
import {LancryptAuditlogModule} from './views/lancrypt/lancrypt-auditlog/lancrypt-auditlog.module';
import {ContactMgmModule} from './views/account/contact-mgm/contact-mgm.module';
import {PublicModule} from './views/public/public.module';
import {MatDialogModule} from '@angular/material/dialog';
import {DualOptionDialogModule} from './shared/components/dual-option-dialog/dual-option-dialog.module';
import {BusyServiceModule} from './shared/components/busy-service/busy-service.module';
import {BusyInterceptor} from './shared/components/busy-service/busy-interceptor';
import {LancryptConnectionsModule} from './views/lancrypt/lancrypt-connections/lancrypt-connections.module';
import {LancryptDashboardModule} from './views/lancrypt/lancrypt-dashboard/lancrypt-dashboard.module';
import {LancryptSettingsModule} from './views/lancrypt/lancrypt-settings/lancrypt-settings.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {Insights} from './services/insights.service';
import {ApplicationinsightsAngularpluginErrorService} from '@microsoft/applicationinsights-angularplugin-js';
import {SubscriptionModule} from './views/account/subscriptions/subscriptions.module';
import {ClientsModule} from './views/account/clients/clients.module';
import {DataExportModule} from './views/account/data-export/data-export.module';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {TranslationHelperService} from './services/helper/translation-helper.service';
import {NgxLazyMatSelectModule} from 'ngx-lazy-mat-select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AdministratorsModule} from './views/account/administrators/administrators.module';
import {RemoveIfFeatureIsOffDirective} from './shared/remove-if-feature-is-off.directive';
import {AppRootComponent} from './app-root.component';
import {TenantRootComponent} from './tenant-root.component';
import {MatTabLink, MatTabNav, MatTabNavPanel} from '@angular/material/tabs';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.host,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
    });
}

// This value will be injected by Webpack
declare const I18N_HASH: string;

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, undefined, '.json?v=' + I18N_HASH);
}

const APP_CONTAINERS = [
  EmptyFooterComponent,
  EmptyHeaderComponent,
  EmptyLayoutComponent,

  LancryptAdminFooterComponent,
  LancryptAdminHeaderComponent,
  LancryptAdminLayoutComponent,

  AccountFooterComponent,
  AccountHeaderComponent,
  AccountLayoutComponent,
];

// Inject/provide dynamic locale id
export class DynamicLocaleId extends String {
  constructor(protected service: TranslationHelperService) {
    super('');
  }

  override toString() {
    return this.service.getRegionalLocale();
  }
}

@NgModule({
  declarations: [AppComponent, AppRootComponent, TenantRootComponent, ...APP_CONTAINERS],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxLazyMatSelectModule,
    UserMenuModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    KeycloakAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AvatarModule,
    BreadcrumbModule,
    FooterModule,
    DropdownModule,
    GridModule,
    HeaderModule,
    SidebarModule,
    IconModule,
    NavModule,
    ButtonModule,
    FormModule,
    UtilitiesModule,
    ButtonGroupModule,
    ReactiveFormsModule,
    SidebarModule,
    SharedModule,
    TabsModule,
    ListGroupModule,
    ProgressModule,
    BadgeModule,
    ListGroupModule,
    CardModule,
    ImgModule,
    PersonalInfoModule,
    AccountHomeModule,
    LancryptAssetsModule,
    LancryptAuditlogModule,
    LancryptDashboardModule,
    RecaptchaV3Module,
    HttpClientModule,
    LancryptIdentitiesModule,
    NgMultiSelectDropDownModule.forRoot(),
    ContactMgmModule,
    PublicModule,
    DualOptionDialogModule,
    MatDialogModule,
    BusyServiceModule,
    LancryptConnectionsModule,
    MatButtonModule,
    MatIconModule,
    LancryptSettingsModule,
    MatIconModule,
    SubscriptionModule,
    AdministratorsModule,
    ClientsModule,
    DataExportModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatMomentDateModule,
    NgxMatSelectSearchModule,
    MatTooltipModule,
    RemoveIfFeatureIsOffDirective,
    MatTabNav,
    MatTabLink,
    MatTabNavPanel,
  ],
  providers: [
    Insights,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    IconSetService,
    Title,
    {provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true},
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslationHelperService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
