import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {GridModule} from '@coreui/angular';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {LancryptAuditlogComponent} from './lancrypt-auditlog.component';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuditlogDetailComponent} from './components/auditlog-detail/auditlog-detail.component';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatDividerModule} from '@angular/material/divider';
import {NotAvailablePipe} from '../../../shared/not-available.pipe';
import {NgxLazyMatSelectModule} from 'ngx-lazy-mat-select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

@NgModule({
  declarations: [LancryptAuditlogComponent, AuditlogDetailComponent, NotAvailablePipe],
  imports: [
    ClipboardModule,
    NgxJsonViewerModule,
    CommonModule,
    MatTableModule,
    GridModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    BusyServiceModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatDividerModule,
    NgxLazyMatSelectModule,
    NgxMatSelectSearchModule,
  ],
  providers: [MatDatepickerModule],
})
export class LancryptAuditlogModule {}
