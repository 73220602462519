import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserDataService} from '../../../services/user-data.service';
import {LogoutService} from '../../../services/auth/logout.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  initials = '';
  firstname = '';
  lastname = '';
  email = '';

  constructor(
    private logoutService: LogoutService,
    private userDataService: UserDataService,
    private router: Router
  ) {
    this.userDataService.userData.subscribe(userData => {
      this.firstname = userData.firstName;
      this.lastname = userData.lastName;
      this.email = userData.email;
      this.initials = this.renderInitials(userData.firstName, userData.lastName);
    });
  }

  ngOnInit() {
    const userData = this.userDataService.userData.value;
    this.firstname = userData.firstName;
    this.lastname = userData.lastName;
    this.email = userData.email;
    this.initials = this.renderInitials(userData.firstName, userData.lastName);
  }

  logout() {
    this.logoutService.logout();
  }

  private renderInitials(firstName: string, lastName: string): string {
    return (firstName.substring(0, 1) + lastName.substring(0, 1)).toUpperCase();
  }
}
