<mat-card class="col-md-8 col-sm-10 col-xs-12 col-lg-8">
  <mat-card-header>
    <mat-card-title>{{'menus.contact' | translate}}</mat-card-title>
    <mat-card-subtitle>
      {{'subheadline.contactSub' | translate}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="contactFormGroup" class="dense-form-field-2">
      <c-container>
        <c-row>
          <c-col class="col-md-2 col-lg-2">
            <mat-label>{{'common.forms.company' | translate}}</mat-label>
          </c-col>
          <c-col class="col-md-8 col-lg-4">
            <div>
              {{company?.companyName}}
            </div>
          </c-col>
        </c-row>
        <c-row>
          <c-col class="col-md-2 col-lg-2">
            <mat-label>{{'common.forms.name' | translate}}</mat-label>
          </c-col>
          <c-col class="col-md-8 col-lg-4">
            <div>
              {{userData?.firstName}} {{userData?.lastName}}
            </div>
          </c-col>
        </c-row>

        <c-row>
          <c-col class="col-md-2 col-lg-2">
            <mat-label>{{'common.forms.email' | translate}}</mat-label>
          </c-col>
          <c-col class="col-md-8 col-lg-4">
            <div>
              {{userData?.email}}
            </div>
          </c-col>
        </c-row>
        <c-row>
          <c-col class="col-md-2 col-lg-2 textfield-column">
            <mat-label>{{'common.forms.subject' | translate}}*</mat-label>
          </c-col>
          <c-col class="col-md-8 col-lg-8">
            <mat-form-field>
              <mat-select formControlName="subject" required>
                <mat-option value="dsgvo">{{'contact.dropdown.dsgvo' | translate}}</mat-option>
                <mat-option value="other">{{'contact.dropdown.other' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </c-col>
        </c-row>
        <c-row *ngIf="contactFormGroup.controls.subject.value == 'other'">
          <c-col class="col-md-2 col-lg-2 textfield-column">
            <mat-label>{{'common.forms.message' | translate}}</mat-label>
          </c-col>
          <c-col class="col-md-8 col-lg-8">
            <mat-form-field>
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="3"
                          formControlName="message"></textarea>
            </mat-form-field>
          </c-col>
        </c-row>
      </c-container>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="lancrypt-primary" (click)="send()" disableDuringRequest>
      {{'buttons.send' | translate}}
    </button>
  </mat-card-actions>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
  </mat-card-footer>
</mat-card>
