<div class="col-md-8 col-sm-10 col-xs-12 col-lg-8 content-container">
  <mat-card>
    <mat-card-title>{{'clientsComponent.lancrypt' | translate}}</mat-card-title>
    <mat-card-content>
    </mat-card-content>
    <mat-card-footer>
      <a (click)="downloadFile(LC_WIN)" href="javascript: void(0)">
        <span class="icon-conpal-cib-windows icon-font"></span>
        {{'clientsComponent.downloadText.windows' | translate}}
      </a>
      |
      <a (click)="downloadFile(LC_MAC)" href="javascript: void(0)">
        <span class="icon-conpal-cib-apple icon-font"></span>
        {{'clientsComponent.downloadText.macos' | translate}}
      </a>
    </mat-card-footer>
  </mat-card>

  <mat-card>
    <mat-card-title>{{'clientsComponent.lancrypt2go' | translate}}</mat-card-title>
    <mat-card-content>
      <a (click)="downloadFile(LC2GO_WIN)" href="javascript: void(0)">
        <span class="icon-conpal-cib-windows icon-font"></span>
        {{'clientsComponent.downloadText.windows' | translate}}
      </a>
      |
      <a (click)="downloadFile(LC2GO_MAC)" href="javascript: void(0)">
        <span class="icon-conpal-cib-apple icon-font"></span>
        {{'clientsComponent.downloadText.macosDmg' | translate}}
      </a>
      |
      <a (click)="downloadFile(LC2GO_LINUX)" href="javascript: void(0)">
        <span class="icon-conpal-cib-linux icon-font"></span>
        {{'clientsComponent.downloadText.linux' | translate}}
      </a>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>{{'clientsComponent.lancryptMobile' | translate}}</mat-card-title>
    <mat-card-content>

      <a href="{{'clientsComponent.downloadLinks.lcmobileIos' | translate}}">
        <img cImg height="50" style="margin: 13px 13px 13px 0" ngSrc="assets/img/ios_badge.svg"
             alt="{{'clientsComponent.altTexts.ios' | translate}}" width="150"/>
      </a>
      <a href="{{'clientsComponent.downloadLinks.lcmobileAndroid' | translate}}">
        <img
          height="50"
          style="margin: 13px"
          cImg
          alt="{{'clientsComponent.altTexts.android' | translate}}"
          ngSrc='assets/img/google-play-badge.svg' width="169"/>
      </a>
    </mat-card-content>
  </mat-card>
  <div>
    {{'clientsComponent.legal.ios' | translate}}<br/>
    {{'clientsComponent.legal.android' | translate}}
  </div>
</div>
