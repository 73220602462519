import {Component, Inject, OnInit} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatButton} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {DialogData} from '../dual-option-dialog/dual-option-dialog.component';
import {NgForOf, NgIf} from '@angular/common';

@Component({
  templateUrl: 'dialog-whats-new.component.html',
  selector: 'dialog-whats-new.component',
  styleUrl: 'dialog-whats-new.component.scss',
  standalone: true,
  imports: [
    TranslateModule,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatDialogTitle,
    NgForOf,
    NgIf,
  ],
})
export class DialogWhatsNew implements OnInit {
  public json: any;

  constructor(
    public dialogRef: MatDialogRef<DialogWhatsNew>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateService.get('whatsNew.whatsNewJsonUrl').subscribe(value => {
      fetch(value)
        .then(response => response.json())
        .then(json => {
          this.json = json;
        })
        .catch(reason => {
          console.log(reason);
        });
    });
  }
}
