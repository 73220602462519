import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LancryptAssetsComponent} from './lancrypt-assets.component';
import {MatTableModule} from '@angular/material/table';
import {GridModule} from '@coreui/angular';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {AssetCreateComponent} from './components/asset-create/asset-create.component';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LancryptMultiselectDropdownModule} from '../../../shared/components/lancrypt-multiselect-dropdown/lancrypt-multiselect-dropdown.module';
import {MatRadioModule} from '@angular/material/radio';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AssetCommonComponent} from './components/asset-common/asset-common.component';
import {AssetUpdateComponent} from './components/asset-update/asset-update.component';
import {MatSortModule} from '@angular/material/sort';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDividerModule} from '@angular/material/divider';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {NgxLazyMatSelectModule} from 'ngx-lazy-mat-select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [LancryptAssetsComponent, AssetCreateComponent, AssetCommonComponent, AssetUpdateComponent],
  imports: [
    CommonModule,
    MatTableModule,
    GridModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    LancryptMultiselectDropdownModule,
    MatRadioModule,
    MatPaginatorModule,
    MatSortModule,
    BusyServiceModule,
    MatProgressBarModule,
    MatDividerModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    MatMenuModule,
    NgxLazyMatSelectModule,
    NgxMatSelectSearchModule,
    MatTooltipModule,
  ],
})
export class LancryptAssetsModule {}
