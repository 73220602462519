import {Component} from '@angular/core';
import {AssetCommonComponent} from '../asset-common/asset-common.component';
import {AssetUpdateDto, AssetViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {FormBuilder} from '@angular/forms';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {PlaceholderService} from '../../placeholder.service';

@Component({
  selector: 'app-asset-update',
  templateUrl: '../asset-common/asset-common.component.html',
  styleUrls: ['../asset-common/asset-common.component.scss'],
})
export class AssetUpdateComponent extends AssetCommonComponent {
  assetId?: string;
  asset?: AssetViewDto;

  constructor(
    private route: ActivatedRoute,
    _formBuilder: FormBuilder,
    _apiClientFactory: ApiClientFactoryService,
    _jwtHelperService: JwtHelperService,
    toastService: ToastService,
    translationService: TranslateService,
    router: Router,
    placeholderService: PlaceholderService
  ) {
    super(
      _formBuilder,
      _apiClientFactory,
      _jwtHelperService,
      toastService,
      translationService,
      router,
      placeholderService
    );
    this.formGroup.controls.assetType.disable();
  }

  override ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.assetId = params['assetId'];

      this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
        this.assetService.getAssetById(tenantId, this.assetId!).subscribe({
          next: (n: AssetViewDto) => {
            this.asset = n;
            super.ngOnInit();
          },
          error: (_: any) => {
            this.toastService.showError(
              this.translationService.instant('common.error'),
              this.translationService.instant('assets.errorFetchingAsset')
            );
          },
          complete: () => {},
        });
      });
    });
  }

  protected override buildDto(): AssetUpdateDto {
    return {
      id: this.assetId!,
      name: this.formGroup.controls.name.value ?? '',
      location: this.placeholderService.translateFromLocalizedPlaceholder(this.formGroup.controls.location.value ?? ''),
      grantAccessGroups: this.assignedGroups,
      grantAccessUsers: this.assignedUsers,
    };
  }

  protected override callService(tenantId: string, dto: AssetUpdateDto): Observable<any> {
    return this.assetService.updateAsset(dto, tenantId);
  }

  protected override getAsset(): AssetViewDto | undefined {
    return this.asset;
  }

  override getTitle(): string {
    return this.translationService.instant('assets.editAsset');
  }

  override getErrorMessage(): string {
    return this.translationService.instant('assets.errorUpdatingAsset');
  }

  override getSuccessMessage(): string {
    return this.translationService.instant('assets.assetUpdated');
  }

  override groupsAndUsersHint(): boolean {
    return false;
  }
}
