import {Component} from '@angular/core';
import {DownloadFileResponseDto, FileService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {ToastService} from '../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent {
  private fileService: FileService;

  LC2GO_WIN = 'LC2GO_WINDOWS';
  LC2GO_MAC = 'LC2GO_MACOS';
  LC2GO_LINUX = 'LC2GO_LINUX';

  LC_WIN = 'LANCRYPT_WINDOWS';
  LC_MAC = 'LANCRYPT_MACOS';

  constructor(
    private apiClientFactoryService: ApiClientFactoryService,
    private toastService: ToastService,
    private translationService: TranslateService
  ) {
    this.fileService = apiClientFactoryService.getFileService();
  }

  downloadFile(filetype: string) {
    this.fileService.downloadFile(filetype).subscribe({
      next: (n: DownloadFileResponseDto) => {
        window.open(n.downloadLink);
      },
      error: (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('clientsComponent.errors.downloadFailed')
        );
      },
    });
  }
}
