import {animate, state, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {
  SortOrderDto,
  UserSearchRequestDto,
  UserService,
  UserTreeViewDto,
  UserTreeViewWithActivityDto,
  UserTreeViewWithActivityPageResultDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {SubFolderType} from 'src/app/dtos/lancrypt/GroupTreeViewIconDto';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {Router} from '@angular/router';
import {GroupTreeViewFlatNode} from '../../../../../dtos/lancrypt/GroupTreeViewFlatNode';
import {DualOptionDialog} from 'src/app/shared/components/dual-option-dialog/dual-option-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
} from '../../../../../shared/lancrypt.constants';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-members-view',
  templateUrl: './members-view.component.html',
  styleUrls: ['./members-view.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MembersViewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() activeNode: GroupTreeViewFlatNode | undefined;

  pageSize = DEFAULT_PAGE_SIZE;
  currentPage = 0;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  totalRows!: number;

  currentLoadedGroupId: string | null | undefined = null;
  dataSource: MatTableDataSource<MemberElement> = new MatTableDataSource<MemberElement>();
  columnsToDisplay = ['name', 'description', 'lastActivity', 'status', 'rootGroup'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: MemberElement | undefined;

  userService: UserService;

  SubFolderType = SubFolderType;
  StatusEnum = UserTreeViewDto.StatusEnum;

  nameFilterValue = '';
  nameFilter = new Subject<string>();
  nameFilterTrigger = this.nameFilter.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged());

  sortOrderDto: SortOrderDto = {
    descending: false,
    column: 'name',
  };

  private tenantId?: string;

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private jwtHelperService: JwtHelperService,
    private router: Router,
    public dialog: MatDialog,
    apiClientFactory: ApiClientFactoryService
  ) {
    this.userService = apiClientFactory.getUserService();
    this.setupSortingProperties();
  }

  ngOnInit(): void {
    this.nameFilterTrigger.subscribe(currentValue => {
      this.currentPage = 0;
      this.nameFilterValue = currentValue.trim().toLowerCase();
      this.loadUsers(this.activeNode?.id);
    });
  }

  ngOnChanges(): void {
    if (this.activeNode) {
      // Update columns
      this.columnsToDisplay = ['name', 'description', 'lastActivity', 'status'];
      if (this.activeNode.subType === SubFolderType.AllUsers) {
        this.columnsToDisplay.push('rootGroup');
      }
      this.columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];

      switch (this.activeNode.subType) {
        case SubFolderType.Members:
          if (this.currentLoadedGroupId !== this.activeNode.id) {
            this.loadUsers(this.activeNode.id);
          }
          break;
        case SubFolderType.Users:
        case SubFolderType.AllUsers:
          if (this.currentLoadedGroupId !== this.activeNode.id) {
            this.loadUsers(this.activeNode.id);
          }
          break;
        default:
          this.dataSource.data = [];
          this.currentLoadedGroupId = null;
          break;
      }
    }
  }

  ngAfterViewInit(): void {
    this.sort!.sortChange.subscribe(sortEvent => {
      this.sortOrderDto = {
        descending: sortEvent.direction === 'desc',
        column: sortEvent.active,
      };
      this.loadUsers(this.activeNode?.id);
    });
  }

  newUser() {
    return this.router.navigate(['lancrypt', 'identities', 'create-user']);
  }

  showEditUser(element: MemberElement) {
    return !element.syncedUser;
  }

  editUser(element: MemberElement) {
    return this.activeNode?.subType !== SubFolderType.Users
      ? this.router.navigate(['lancrypt', 'identities', 'edit-user', element.id, this.activeNode!.id])
      : this.router.navigate(['lancrypt', 'identities', 'edit-user', element.id]);
  }

  showUserDetails(element: MemberElement) {
    return this.activeNode?.subType !== SubFolderType.Users
      ? this.router.navigate(['lancrypt', 'identities', 'details-user', element.id, this.activeNode!.id])
      : this.router.navigate(['lancrypt', 'identities', 'details-user', element.id]);
  }

  allowEditMemberAssignment(): boolean {
    return (
      this.activeNode !== undefined && this.activeNode.subType === SubFolderType.Members && !this.activeNode.synced
    );
  }

  applyFilter(event: Event) {
    this.nameFilter.next((event.target as HTMLInputElement).value);
  }

  private loadUsers(groupId: string | undefined): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      const searchRequestTest: UserSearchRequestDto = this.buildSearchDto();
      this.userService.getUsersWithActivity(searchRequestTest, this.tenantId!, groupId, false).subscribe({
        next: (n: UserTreeViewWithActivityPageResultDto) => {
          this.dataSource.data = this.mapToMemberElement(n.content);
          this.totalRows = n.totalElements;
          this.paginator.pageIndex = this.currentPage;
          this.currentLoadedGroupId = groupId;
        },
        error: (_: any) => {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('users.errorFetchingUsers')
          );
        },
        complete: () => {},
      });
    });
  }

  resendActivation(userId: string) {
    if (userId && this.tenantId) {
      this.dialog
        .open(DualOptionDialog, {
          width: '350px',
          data: {
            title: this.translateService.instant('users.details.resendActivation'),
            description: this.translateService.instant('activateAccount.resendActivationDialogDesc'),
            positiveTitle: this.translateService.instant('common.confirm'),
            negativeTitle: this.translateService.instant('common.cancel'),
          },
        })
        .afterClosed()
        .subscribe(result => {
          if (result === true) {
            this.userService.resendActivation(this.tenantId!, userId).subscribe({
              next: (_: any) => {
                this.toastService.showSuccess(
                  this.translateService.instant('common.success'),
                  this.translateService.instant('activateAccount.activationEmailSent')
                );
              },
              error: (e: any) => {
                let msg = 'activateAccount.error.activateAccount';
                if (e instanceof HttpErrorResponse && e.status === 405) {
                  msg = 'activateAccount.error.alreadyActivated';
                }
                this.toastService.showError(
                  this.translateService.instant('common.error'),
                  this.translateService.instant(msg)
                );
              },
            });
          }
        });
    }
  }

  private buildSearchDto(): UserSearchRequestDto {
    return {
      pageRequest: {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
      },
      searchString: this.nameFilterValue,
      sortOrder: this.sortOrderDto,
    };
  }

  removeAssignment(element: MemberElement) {
    const dialogRef = this.dialog.open(DualOptionDialog, {
      width: '500px',
      data: {
        title: this.translateService.instant('treeview.removeAssignment'),
        description: this.translateService.instant('treeview.removeUserAssignmentEnquiry'),
        positiveTitle: this.translateService.instant('common.confirm'),
        negativeTitle: this.translateService.instant('common.cancel'),
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.userService.deleteUserToGroupAssignment(this.tenantId!, element.id, this.activeNode!.id).subscribe({
            next: async (_: any) => {
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('treeview.deletedMemberAssignmentFromGroup')
              );
              this.loadUsers(this.activeNode?.id);
            },
            error: async (_: any) => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('errors.deleteMemberAssignmentFromGroup')
              );
            },
          });
        }
      },
    });
  }

  private mapToMemberElement(users: UserTreeViewWithActivityDto[]): MemberElement[] {
    return users.map(u => {
      return {
        id: u.id,
        name: u.displayName,
        description: u.description ?? '',
        status: u.status,
        emailAddress: u.emailAddress,
        syncedUser: u.syncedUser,
        rootGroup: u.rootGroup,
        lastActivity: u.lastProfileFetch,
      };
    });
  }

  private setupSortingProperties() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        default:
          return (data as any)[sortHeaderId].toLowerCase();
      }
    };
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadUsers(this.activeNode?.id);
  }
}

export interface MemberElement {
  id: string;
  name: string;
  description?: string;
  status: UserTreeViewDto.StatusEnum;
  emailAddress: string;
  syncedUser: boolean;
  rootGroup: string;
  lastActivity?: Date;
}
