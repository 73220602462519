import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {TenantCreateDto, TenantService, UserService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ToastService} from '../../../services/toaster.service';
import {FIELD_LENGTH_CONSTRAINTS, PASSWORD_REQUIREMENTS_PATTERN} from '../../../shared/lancrypt.constants';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {CustomValidators} from '../../../shared/components/validators/custom-validators';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';
import {LogoutService} from '../../../services/auth/logout.service';
import {TranslationHelperService} from '../../../services/helper/translation-helper.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
  tenantApiClient: TenantService;
  userService: UserService;

  maxLengthConstraints = {
    firstName: FIELD_LENGTH_CONSTRAINTS.firstName,
    lastName: FIELD_LENGTH_CONSTRAINTS.lastName,
    email: FIELD_LENGTH_CONSTRAINTS.email,
    password: FIELD_LENGTH_CONSTRAINTS.passwordMax,
    companyName: FIELD_LENGTH_CONSTRAINTS.companyName,
    street1: FIELD_LENGTH_CONSTRAINTS.street1,
    street2: FIELD_LENGTH_CONSTRAINTS.street2,
    city: FIELD_LENGTH_CONSTRAINTS.city,
    state: FIELD_LENGTH_CONSTRAINTS.state,
    postalCode: FIELD_LENGTH_CONSTRAINTS.postalCode,
    country: FIELD_LENGTH_CONSTRAINTS.country,
    mobilePhoneNumber: FIELD_LENGTH_CONSTRAINTS.mobilePhoneNumber,
    businessPhoneNumber: FIELD_LENGTH_CONSTRAINTS.businessPhoneNumber,
  };

  minLengthConstraints = {
    password: FIELD_LENGTH_CONSTRAINTS.passwordMin,
  };

  tokenAvailable = false;

  constructor(
    private _formBuilder: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private translationHelperService: TranslationHelperService,
    private jwtHelperService: JwtHelperService,
    private router: Router,
    private logoutService: LogoutService
  ) {
    this.tenantApiClient = this.apiClientFactory.getTenantService();
    this.userService = this.apiClientFactory.getUserService();

    this.firstFormGroup = this._formBuilder.group(
      {
        firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.firstName)]],
        lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.lastName)]],
        email: [
          '',
          [Validators.email, Validators.maxLength(this.maxLengthConstraints.email)],
          [CustomValidators.createEmailValidator(true, this.userService)],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.maxLength(this.maxLengthConstraints.password),
            Validators.minLength(this.minLengthConstraints.password),
            Validators.pattern(PASSWORD_REQUIREMENTS_PATTERN),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {validators: this.checkPasswords}
    );
  }

  logout() {
    this.logoutService.logout();
  }

  ngOnInit(): void {
    this.jwtHelperService.getToken().then(token => {
      if (!token) {
        return;
      }

      if (token.tenantIds && token.tenantIds.length > 0) {
        //reroute back to dashboard, if the user already has a tenant
        this.router.navigate(['/']);
      } else {
        this.tokenAvailable = true;
        this.firstFormGroup.patchValue({
          firstName: token.given_name,
          lastName: token.family_name,
          email: token.email,
        });
        const email = this.firstFormGroup.controls.email;
        email.clearAsyncValidators();
        email.updateValueAndValidity();

        const password = this.firstFormGroup.controls.password;
        password.clearValidators();
        password.updateValueAndValidity();

        const confirmPassword = this.firstFormGroup.controls.confirmPassword;
        confirmPassword.clearValidators();
        confirmPassword.updateValueAndValidity();
      }
    });
  }

  showPassword = false;
  showConfirmPassword = false;
  submitted = false;
  buttonDisabled = false;
  checkConsent = false;

  matcher = new DirtyErrorStateMatcher();

  cookieConsentFormGroup = this._formBuilder.group({
    consentCheckbox: [false, Validators.requiredTrue],
  });

  firstFormGroup; //initialized in constructor because it needs userService
  secondFormGroup = this._formBuilder.group({
    companyName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.companyName)]],
    street1: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.street1)]],
    street2: ['', Validators.maxLength(this.maxLengthConstraints.street2)],
    city: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.city)]],
    state: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.state)]],
    postalCode: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.postalCode)]],
    country: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.country)]],
  });

  fourthFormGroup = this._formBuilder.group({
    mobilePhoneNumber: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.mobilePhoneNumber)]],
    businessPhoneNumber: ['', Validators.maxLength(this.maxLengthConstraints.businessPhoneNumber)],
    acceptTerms: [false, Validators.requiredTrue],
  });

  acceptTermsError() {
    return (
      this.fourthFormGroup.hasError('required', 'acceptTerms') &&
      (this.submitted || this.fourthFormGroup.controls.acceptTerms.touched)
    );
  }

  acceptCookieConsentError() {
    return (
      this.cookieConsentFormGroup.hasError('required', 'consentCheckbox') &&
      (this.checkConsent || this.cookieConsentFormGroup.controls.consentCheckbox.touched)
    );
  }

  buildTenantDto(): TenantCreateDto {
    return {
      tenant: {
        companyName:
          this.secondFormGroup.controls.companyName.value === null
            ? ''
            : this.secondFormGroup.controls.companyName.value,
        address: {
          street1:
            this.secondFormGroup.controls.street1.value === null ? '' : this.secondFormGroup.controls.street1.value,
          street2:
            this.secondFormGroup.controls.street2.value === null ? '' : this.secondFormGroup.controls.street2.value,
          city: this.secondFormGroup.controls.city.value === null ? '' : this.secondFormGroup.controls.city.value,
          state: this.secondFormGroup.controls.state.value === null ? '' : this.secondFormGroup.controls.state.value,
          zip:
            this.secondFormGroup.controls.postalCode.value === null
              ? ''
              : this.secondFormGroup.controls.postalCode.value,
          country:
            this.secondFormGroup.controls.country.value === null ? '' : this.secondFormGroup.controls.country.value,
        },
        vatNr: '',
        agreedToTerms:
          this.fourthFormGroup.controls.acceptTerms.value === null
            ? false
            : this.fourthFormGroup.controls.acceptTerms.value,
      },
      adminUser: {
        firstName:
          this.firstFormGroup.controls.firstName.value === null ? '' : this.firstFormGroup.controls.firstName.value,
        lastName:
          this.firstFormGroup.controls.lastName.value === null ? '' : this.firstFormGroup.controls.lastName.value,
        emailAddress: this.firstFormGroup.controls.email.value === null ? '' : this.firstFormGroup.controls.email.value,
        password:
          this.firstFormGroup.controls.password.value === null ? '' : this.firstFormGroup.controls.password.value,
        mobilePhoneNumber:
          this.fourthFormGroup.controls.mobilePhoneNumber.value === null
            ? ''
            : this.fourthFormGroup.controls.mobilePhoneNumber.value,
        businessPhoneNumber:
          this.fourthFormGroup.controls.businessPhoneNumber.value === null
            ? ''
            : this.fourthFormGroup.controls.businessPhoneNumber.value,
        languageInfo: this.translationHelperService.getCurrentTranslationLanguage(),
        regionalFormats: this.translationHelperService.getCurrentTranslationLanguage(),
      },
    };
  }

  formsValid(): boolean {
    return (
      this.cookieConsentFormGroup.valid &&
      this.firstFormGroup.valid &&
      this.secondFormGroup.valid &&
      this.fourthFormGroup.valid
    );
  }

  createAccount() {
    this.submitted = true;

    if (!this.formsValid()) {
      return;
    }

    this.buttonDisabled = true;
    this.recaptchaV3Service.execute('createAccount').subscribe(async (_: string) => {
      const createModel = this.buildTenantDto();

      this.tenantApiClient.createTenant(createModel).subscribe({
        next: () => {},
        error: () => {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('errors.createAccount')
          );
          this.buttonDisabled = false;
        },
        complete: () => {
          this.toastService.showSuccess(
            this.translateService.instant('common.success'),
            this.translateService.instant('createAccount.tenantCreated')
          );
          if (this.tokenAvailable) {
            setTimeout(() => {
              this.buttonDisabled = false;
              this.logout(); //TODO CC-774: seems like we need to refresh/update the token?!, or just logout the user
            }, 1500);
          } else {
            this.router.navigate(['public', 'verify-email', createModel.adminUser.emailAddress]);
            this.buttonDisabled = false;
          }
        },
      });
    });
  }

  checkPasswords(group: AbstractControl): ValidationErrors | null {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    return password === confirmPassword ? null : {notMatch: true};
  }

  getAddressForSummary() {
    const addressData = [];

    addressData.push(this.secondFormGroup.controls.companyName.value);
    addressData.push(this.secondFormGroup.controls.street1.value);

    if (this.secondFormGroup.controls.street2.value) {
      addressData.push(this.secondFormGroup.controls.street2.value);
    }

    addressData.push(this.secondFormGroup.controls.city.value);
    addressData.push(this.secondFormGroup.controls.state.value);
    addressData.push(this.secondFormGroup.controls.postalCode.value);
    addressData.push(this.secondFormGroup.controls.country.value);

    return addressData.join(', ');
  }

  checkCookieConsent() {
    this.checkConsent = true;
  }
}

export class DirtyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, _: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty);

    return (invalidCtrl || invalidParent) && control?.touched;
  }
}
